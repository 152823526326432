<template>
  <div>
    <!-- <b-row
      no-gutters
      class="match-height col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12"
      style="margin: 0px; padding: 0px;"
    >
      <b-col>
        <PostDetail
          v-if="postLocal !== undefined"
          :post="postLocal"
        />
      </b-col>
    </b-row>

    <b-row class="side-bar col-sm-12 col-md-4 col-lg-4 col-xl-3 col-12">
      <b-col>
        <PostDetail
          v-if="postLocal !== undefined"
          :post="postLocal"
        />
      </b-col>
    </b-row> -->

    <div
      class="container"
      style="display: inline-flex; flex-flow: column wrap; margin: 0px; padding: 0px;"
    >
      <!-- [{{ sport }}] [{{ team }}] [{{ slug }}] -->
      <b-row
        no-gutters
        class="column d-md-block"
        style="margin: 0px; padding: 0px;"
      >
        <b-col
          class="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12 float-md-left"
          style="margin: 0px; padding: 5px;"
        >
          <PostDetail
            v-if="postFound && postLocal !== undefined"
            :post="postLocal"
          />
          <div
            v-else
            class="alert failure"
            style="margin: 20px 0 20px 0;"
          >
            <strong>Oops, the post is no longer active. Check out these related ones.</strong>
          </div>
        </b-col>

        <b-col
          class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-12 float-md-right"
          style="margin: 0px; padding: 5px;"
        >
          <div>
            <people-suggestions
              :people="people"
            />
          </div>
          <div> C </div>
        </b-col>

        <b-col
          v-show="randomPosts.length > 0"
          class="col-sm-12 col-md-12 col-lg-12 col-xl-9 col-12 float-md-left"
          style="margin: 0px; padding: 5px;"
        >
          <!-- <view-composite
            :posts="randomPosts"
            sport="Others"
            style="margin-bottom: 20px;"
          /> -->
          <div style="display: inline-flex; flex-direction: row; flex-wrap: wrap; align-items: flex-start;">
            <b-row
              no-gutters
              class="match-height"
              style="margin: 0px; padding: 0px;"
            >
              <h6
                class="kf_hd2"
                style="margin-top: 10px;"
              >
                <span>
                  <strong class="titleClass">Related ...</strong>
                </span>
              </h6>

              <div
                v-for="(thepost, index) in randomPosts"
                :key="thepost.id"
                class="col-sm-6 col-md-4 col-lg-4 col-12"
              >
                <post
                  :key="thepost.id"
                  :post="thepost"
                  :collapsed="true"
                  :tmp="index"
                  style="margin-left: 5px; margin-right: 5px; margin-bottom: 10px;"
                />
              </div>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>

    <ScrollToTop />
    <BackRoute />
  </div>
</template>

<script>
import {
  reactive, toRefs, computed, watch,
} from '@vue/composition-api'

import { subject as an } from '@casl/ability'

import store from '@/store'
import router from '@/router'

import {
  BRow, BCol,
} from 'bootstrap-vue'

import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import BackRoute from '@core/components/back-route/BackRoute.vue'
import PostDetail from './PostDetail.vue'
import Post from './Post.vue'
import PeopleSuggestions from '../sports/PeopleSuggestions.vue'
// import ViewComposite from '../sports/ViewComposite.vue'

import * as PostService from '../../services/PostService'

export default {
  components: {
    BRow,
    BCol,

    ScrollToTop,
    BackRoute,
    PostDetail,
    Post,
    PeopleSuggestions,
    // ViewComposite,
  },

  props: {
    sport: {
      type: String,
      default: '',
    },
    team: {
      type: String,
      default: '',
    },
    slug: {
      type: String,
      default: '',
    },

    post: {
      type: String,
      default: '',
    },
  },

  setup(props, context) {
    const userId = computed(() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        // event.doesUserLike = props.post.likes.includes(userData._id)

        /* eslint no-underscore-dangle: ["error", { "allow": ["foo_", "_id"] }] */
        return userData._id
      }
      return 'n/a'
    })

    const event = reactive({
      postLocal: {},
      randomPosts: [],
      people: [],
      postFound: false,
    })

    function showLoginBox() {
      context.root.$bvModal
        .msgBoxOk('Sign in required.', {
          centered: true,
        })
    }

    function showMsgBoxOne() {
      context.root.$bvModal
        .msgBoxConfirm('Are you sure?', {
          cancelVariant: 'outline-secondary',
        })
        .then(value => {
          if (value) {
            PostService.deletePost(event.postLocal.id)
            // .catch(error => {
            //   console.log(error)
            // })
            // router.push(`/authors/post/edit/${props.post.id}`)
          }
        })
    }

    function goBack() {
      router.go(-1)
    }

    watch(
      () => context.root.$route.params.post,
      val => {
        if (val !== undefined) {
          event.postLocal = an('Post', JSON.parse(val))
          event.postFound = true
          // event.randomPosts = computed(() => store.getters['appData/getRandomPostsForSportTeam'](event.postLocal.categories[0], event.postLocal.team).map(item => an('Post', item)))
          event.randomPosts = computed(() => store.getters['appData/getRandomPostsForSportTeam'](event.postLocal.categories[0]).map(item => an('Post', item)))

          if (event.randomPosts.length === 0) {
            const sportTmp = event.postLocal.categories[0]
            const teamTmp = event.postLocal.team
            if (teamTmp !== undefined && teamTmp !== '') {
              if (sportTmp !== undefined && sportTmp !== '') {
                PostService.getAllPostsFromCategoryWithSearch(sportTmp, teamTmp, 6)
                  .then(response => {
                    event.randomPosts = computed(() => response.data.posts.map(item => an('Post', item)))
                  })
                  // .catch(error => {
                  //   console.log('===================ERROR')
                  //   console.log(error)
                  // })

                event.people = computed(() => {
                  const data = store.getters['appData/getPeople']
                  return data.filter(person => (person.sport === sportTmp))
                })
              }
            }
          }
        }
      },
    )

    // watch(
    //   () => props.slug,
    //   val => {
    //     alert(val)
    //   },
    // )

    // watch(event.rawPost, val => {
    //   if (val !== undefined) {
    //     event.postLocal = an('Post', JSON.parse(val))
    //     event.randomPosts = computed(() => store.getters['appData/getRandomPostsForSportTeam'](event.postLocal.categories[0], event.postLocal.team).map(item => an('Post', item)))
    //   }
    // })

    if (context.root.$route.params.post !== undefined) {
      event.rawPost = context.root.$route.params.post
      event.postLocal = an('Post', JSON.parse(context.root.$route.params.post))
      event.postFound = true
      // event.randomPosts = computed(() => store.getters['appData/getRandomPostsForSportTeam'](event.postLocal.categories[0], event.postLocal.team).map(item => an('Post', item)))
      event.randomPosts = computed(() => store.getters['appData/getRandomPostsForSportTeam'](event.postLocal.categories[0]).map(item => an('Post', item)))

      if (event.randomPosts.length === 0) {
        const sportTmp = event.postLocal.categories[0]
        const teamTmp = event.postLocal.team
        if (teamTmp !== undefined && teamTmp !== '') {
          if (sportTmp !== undefined && sportTmp !== '') {
            PostService.getAllPostsFromCategoryWithSearch(sportTmp, teamTmp, 6)
              .then(response => {
                event.randomPosts = computed(() => response.data.posts.map(item => an('Post', item)))
              })
              // .catch(error => {
              //   console.log('===================ERROR')
              //   console.log(error)
              // })

            event.people = computed(() => {
              const data = store.getters['appData/getPeople']
              return data.filter(person => (person.sport === sportTmp))
            })
          }
        }
      }
    }

    if (event.postLocal === undefined || Object.keys(event.postLocal).length === 0) {
      if (context.root.$route.params.post === undefined) {
        PostService.getPostBySlug(context.root.$route.params.slug)
          .then(response => {
            event.postLocal = an('Post', response.data.data.data)
            event.postFound = true
            // const sportTmp = context.root.$route.params.sport
            // const teamTmp = context.root.$route.params.team
            const sportTmp = event.postLocal.categories[0]
            const teamTmp = event.postLocal.team

            if (teamTmp !== undefined && teamTmp !== '') {
              if (sportTmp !== undefined && sportTmp !== '') {
                PostService.getAllPostsFromCategoryWithSearch(sportTmp, teamTmp, 6)
                  .then(response2 => {
                    event.randomPosts = computed(() => response2.data.posts.map(item => an('Post', item)))
                  })
                  // .catch(error => {
                  //   console.log('===================ERROR')
                  //   console.log(error)
                  // })

                event.people = computed(() => {
                  const data = store.getters['appData/getPeople']
                  return data.filter(person => (person.sport === sportTmp))
                })
              }
            }
          })
          .catch(() => {
            const teamTmp = props.team
            const sportTmp = props.sport

            if (sportTmp !== undefined && sportTmp !== '') {
              if (teamTmp !== undefined && teamTmp !== '') {
                PostService.getAllPostsFromCategoryWithSearch(sportTmp, teamTmp, 6)
                  .then(response2 => {
                    event.randomPosts = computed(() => response2.data.posts.map(item => an('Post', item)))
                  })
                  // .catch(error2 => {
                  //   console.log('===================ERROR2a')
                  //   console.log(error2)
                  // })

                event.people = computed(() => {
                  const data = store.getters['appData/getPeople']
                  return data.filter(person => (person.sport === sportTmp))
                })
              }

              PostService.getAllPostsFromCategory(sportTmp, 6)
                .then(response2 => {
                  event.randomPosts = computed(() => response2.data.posts.map(item => an('Post', item)))
                })
                // .catch(error2 => {
                //   console.log('===================ERROR2b')
                //   console.log(error2)
                // })

              event.people = computed(() => {
                const data = store.getters['appData/getPeople']
                return data.filter(person => (person.sport === sportTmp))
              })
            }
          })
      }
    }

    if (event.people.length === 0 && event.postLocal !== undefined && event.postLocal.categories !== undefined && event.postLocal.categories[0] !== '') {
      event.people = computed(() => {
        const data = store.getters['appData/getPeople']
        return data.filter(person => (person.sport === event.postLocal.categories[0]))
      })
    }

    return {
      ...toRefs(event),
      showMsgBoxOne,
      showLoginBox,
      goBack,
      userId,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '@core/scss/vue/pages/page-blog.scss';

$border: #ebebeb;
$text: #444;
$catpad: 15px;

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

body { font-family: sans-serif; }
h3 { text-align: center; }

.a, .b, .c, .d {
  min-height:100px;
}

.tall {
  min-height:200px;
}

.double {
  min-height:250px;
}

.super {
  min-height:650px;
}

.page-link-local, .page-link-local:visited, .page-link-local:hover, .page-link-local:active, .page-link-local:focus {
  outline: none !important;
}

.image-tmp {
  height: 100%;
  max-height: 200px;

  // Sets the maximum height to higher value when the card changes
  // from picture on top to picture on left side.
  @media screen and (max-width: 992px) {
    max-height: 600px;
  }

  overflow: hidden;
  transition: transform 0.5s ease;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  backface-visibility: hidden;
  object-fit: cover;
}

.image-tmp:hover {
  transform: scale(1.1);
}

.post-category {
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.0625rem;
  margin: $catpad 0.0rem 0.0rem $catpad;

  @media screen and (max-width: 992px) {
    font-size: 0.7em;
    margin: 10px 0 0 $catpad;
  }
}

.post-title {
  @include transition(color 0.3s ease);
  font-size: 1.2rem;
  line-height: 1.15;
  font-weight: 500;
  overflow: hidden;
  color: $primary;

  @media screen {
    height: 100%;
  }

  @media screen and (max-width: 992px) {
    font-size: 1.1em;
  }
}

.post-description {
  @include transition(color 0.3s ease);
  font-size: 1.2rem;
  line-height: 1.15;
  color: $text;
  font-weight: 400;
  width: 90wv;
  padding: 0;
}

.post-info {
  text-align: center;
  color: #929599;
  font-size: 11px;
  // width: 100%;
  // // position: relative;
  // bottom: 5px;
  //padding: 30px 0 0 0;

  position: absolute;
  bottom: 5px;
  right: 10px;
  left: 10px;
  /* this is the key */
  max-height:100%;
}
.post-info-pic {
  display: inline-block;
  text-align: center;
  color: #929599;
  padding-top: 20px;
  font-size: 11px;
  width: 100%;
  position: absolute;
  bottom: 5px;
  margin-top: 10px;
  padding-left: 100px;
}

.post-time {
  // line-height: 1;
  // z-index: 10;
  // text-align: left;
  // // position: sticky;
  // // padding: 10px 0 0 0;
  // position: absolute;
  // bottom: -5px;
  // left: 10px;

  line-height: 1;
  z-index: 10;
  text-align: right;
  // position: sticky;
  // padding: 10px 0 0 0;
  position: absolute;
  bottom: 11px;
  right: 10px;
}

.post-author {
  // line-height: 1;
  // z-index: 10;
  // text-align: right;
  // // position: sticky;
  // // padding: 10px 0 0 0;
  // position: absolute;
  // bottom: 11px;
  // right: 10px;
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px 0 0;
 }

.post-row {
  // Fixes the compressed view where the images don't fit the
  // height of the card. If applied to all sizes, even when image
  // is above the text, then the image sizes will be different for
  // different cards.
  @media screen and (max-width: 992px) {
    min-height: 100%;
  }
}

.post-shares {
  text-align: center;
  padding: 5px 0 5px 0;
}

.rounded-card {
  border-radius: 5px;
}

.mc-btn-action {
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid;
  width: 32px;
  height: 32px;
  line-height: 27px;
  text-align: center;
  background-color: $primary;
  color: $secondary;
  cursor: pointer;
  z-index: 2;
  //.mc-transtition();
}

.mc-btn-trash {
  position: absolute;
  right: 45px;
  top: 8px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid;
  width: 32px;
  height: 32px;
  line-height: 27px;
  text-align: center;
  background-color: #c50505;
  color: $secondary;
  cursor: pointer;
  z-index: 2;
  //.mc-transtition();
}

.mc-btn-locked:hover {
  color: red;
}

.mc-btn-unlocked:hover {
  color: rgb(13, 175, 13);
}

a {
  text-decoration: none;
  color: #3498db;
}

.dropdown-menu #zzz {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}

.wrap-dropdown {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}
.dropdown-item {
  max-width: 2rem !important;
  white-space: normal;
  min-width:inherit;
}

.alert {
  position: relative;
  padding: 1.15rem 1.15rem;
  padding-left: 90px;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.alert::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px 0 0 4px;
  width: 70px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.success::before {
  background-color: $secondary;
  background-size: 28px 25px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 12'%3E%3Cpath transform='translate(-189.438 -2446.25)' fill='%23fff' d='M201.45,2446.24l2.121,2.13-9.192,9.19-2.122-2.12Zm-4.949,9.2-2.121,2.12-4.95-4.95,2.121-2.12Z'/%3E%3C/svg%3E");
}

.failure::before {
  background-color: $danger;
  background-size: 28px 25px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 18'%3E%3Cpath transform='translate(-194 -2257)' fill='%23fff' d='M194.714,2257h3.572a0.716,0.716,0,0,1,.714.72l-0.714,11.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72L194,2257.72A0.716,0.716,0,0,1,194.714,2257Zm0.715,14.4h2.142a0.716,0.716,0,0,1,.715.72v2.16a0.716,0.716,0,0,1-.715.72h-2.142a0.716,0.716,0,0,1-.715-0.72v-2.16A0.716,0.716,0,0,1,195.429,2271.4Z'/%3E%3C/svg%3E");
}

.text-success {
  color: $secondary !important;
}

.text-failure {
  color: $danger !important;
}

.ad {
  width: 100%;
  height: auto;
}

.center {
  display: flex;
  justify-content: center;
}
</style>
